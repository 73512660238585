.pageContainer {
  padding: 40px;
  background-color: rgb(245, 246, 248);
  /* height: 90%; */
  flex: 1;
}

.tableContainer {
  border-radius: 12px;
  background: var(--gray-100, #fefeff);
  padding: 20px;
  font-family: Inter Tight !important;
  flex: 1;
}

.detailsTitle {
  font-family: Inter Tight !important;
  font-size: 18px !important;
  line-height: 32px !important;
}
.detailsHeaderValue {
  font-family: Inter Tight !important;
  font-size: 16px !important;
  line-height: 28px !important;
}
.values {
  font-family: Inter Tight !important;
  font-size: 14px !important;
  line-height: 24px !important;
}
.createHeader {
  font-family: Inter Tight !important;
  font-size: 24px !important;
  line-height: 32px !important;
}

.MuiTableCell-root {
  padding: 5px !important;
}

.addUser {
  display: flex;
  justify-content: end;
  align-items: end;
  .btn {
    font-family: Inter Tight !important;
    font-size: 16px !important;
  }
}

.userDetails {
  width: 200px !important;
  max-width: 200px !important;
}

/* .MuiDrawer-paperAnchorRight {
  margin-top: 10px;
  padding: 20px;
  width: 350px !important;
  height: 70% !important;
} */

.userDetailsHeader {
  font-family: Inter Tight !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  margin-bottom: 5px;
}
