* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Inter Tight !important";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter Tight !important";
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  -webkit-appearance: none;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(162, 162, 162); 
  border-radius: 25px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(162, 162, 162); 
  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(98, 97, 97); 
}