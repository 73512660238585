.tableHeader {
  font-family: Inter Tight !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 36px;
  background-color: rgb(245, 246, 248);
  flex: 1;
}
.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 24px;
  border-radius: 8px;
  background: var(--gray-100, #fefeff);
  flex: 1;
  /* font-family: Inter Tight !important; */
}
.detailsTitle {
  font-family: Inter Tight !important;
  font-size: 14px !important;
  line-height: 20 !important;
  color: #878f9c;
}

.milestone-value {
  font-family: Inter Tight !important;
  font-size: 14px !important;
}
.milestone-amount {
  font-family: Inter Tight !important;
  font-size: 14px !important;
  text-align: right !important;
}
.values {
  font-family: Inter Tight !important;
  font-size: 16px !important;
  line-height: 24px !important;
}
.detailsValue {
  font-family: Inter Tight !important;
  font-size: 14px !important;
  line-height: 28px !important;
  color: #475569 !important;
}
.detailsValue:nth-child(3) {
  text-align: right !important;
}
.MuiTableCell-root {
  padding: 14px !important;
}
.iconButton{
  font-size: 1.2rem !important;
  padding: 8px 0 !important;
}
.iconSvg{
  font-size: 1.2rem !important;
}