.alert {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  background: #f2f4f7;
  color: #000000;
}

.label {
  font-family: Inter Tight !important;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.cur {
  font-family: Inter Tight !important;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.fieldTitle {
  color: var(--Text-color-1, #0d1522);
  font-family: Inter Tight !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.btnSubmit {
  display: flex;
  align-items: center;
  color: var(--Primary-Brand-Color, #274bf1);
  font-family: Inter Tight !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}
.secondaryTitle {
  color: var(--gray-800-header-text, #030f1c);
  /* Large/Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do: */
  font-family: Inter Tight !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  padding: 0px 16px;
}
.milestoneHeader {
  color: var(--gray-800-header-text, #030f1c);
  /* Titles/The quick brown fox jumps over the lazy dog */
  font-family: Inter Tight !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.endDate {
  color: var(--light-text-color-2, #8c92a4);
  /* Normal/Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do: */
  font-family: Inter Tight !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.endDateValue {
  color: var(--light-text-color-1, #202939);
  font-family: Inter Tight !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;
}
.viewDetails {
  color: var(--Primary-Brand-Color, #274bf1);
  /* Large/Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do: */
  font-family: Inter Tight !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.conBtn {
  display: flex;
  justify-content: end;
}

.addMilestoneHeader {
  color: #030f1c;
  font-family: Inter Tight !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}
.accLabelValue {
  color: #202939;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
}
.accLabel {
  color: #878f9c;
  font-family: Inter Tight;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.disc {
  font-family: Inter Tight;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
