.cancel {
  border-radius: 7px;
  background: var(--light-surface, #f5f6f8);
  color: var(--light-text-color-1, #202939);
  text-align: center;
  font-family: Inter Tight !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important; /* 150% */
  padding: 8px;
  cursor: pointer;
}
